import { Row, Col, Container } from "react-bootstrap";
import logo from "./Logo.png";
import name from "./cricinshots.png";

export const BottomPhone = () => {
	return (
		<section
			style={{
				maxWidth: "100vw",
				minHeight: "46vw",
				background: "#000000",
				overflowX: "hidden",
				paddingBottom: "3vh",
			}}
			id="bottomPhone"
		>
			<Container>
				<Row>
					<div
						className="grid-container"
						style={{
							display: "grid",
							justifyContent: "center",
							gridTemplateColumns: "auto auto auto",
							rowGap: "2vh",
							columnGap: "10vw",
							marginTop: "4vh",
							fontSize: "0.9rem",
							fontFamily: "Kanit",
							fontWeight: "lighter",
							color: "#FFFFFF",
							textAlign: "center",
						}}
					>
						<div className="grid-item">
							<a href="#home" style={{ textDecoration: "none", color: "white" }}>
								HOME
							</a>
						</div>
						<div className="grid-item">
							<a href="#gameplay" style={{ textDecoration: "none", color: "white" }}>
								GAMEPLAY
							</a>
						</div>
						{/* <div className="grid-item">
							<a href="#roadmap" style={{ textDecoration: "none", color: "white" }}>
								ROADMAP
							</a>
						</div> */}
						{/* <div className="grid-item" /> */}
						{/* <div className="grid-item">
							<a href="#faq" style={{ textDecoration: "none", color: "white" }}>
								FAQ
							</a>
						</div> */}
					</div>
					<div style={{ display: "flex", height: "0vh", justifyContent: "center" }}>
						<div
							style={{
								width: "48vw",
								height: "4vh",
								borderBottom: "1px solid grey",
							}}
						></div>
					</div>
				</Row>
				<Row>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							marginTop: "6vh",
						}}
					>
						<div
							style={{
								fontSize: "1.2rem",
								marginBottom: "3vh",
								fontFamily: "Kanit",
								color: "#FFFFFF",
							}}
						>
							INQUIRY
						</div>
						{/* <div
							style={{
								fontSize: "0.8rem",
								color: "#E7E7E7",
								textAlign: "justify",
								textAlignLast: "center",
								width: "58%",
								fontFamily: "Kanit",
								fontWeight: "lighter",
							}}
						>
							The team always plays an important role in the success of a project.
						</div> */}
					</div>
				</Row>
				<Row>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							// marginTop: "6vh",
						}}
					>
						<div
							style={{
								fontSize: "1.2rem",
								marginBottom: "3vh",
								fontFamily: "Kanit",
								color: "#FFFFFF",
							}}
						>
							GAME SUPPORT
						</div>
						{/* <div
							style={{
								fontSize: "0.8rem",
								color: "#E7E7E7",
								textAlign: "justify",
								textAlignLast: "center",
								width: "58%",
								fontFamily: "Kanit",
								fontWeight: "lighter",
							}}
						>
							The team always plays an important role in the success of a project.
						</div> */}
					</div>
				</Row>
				<Row>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							// marginTop: "6vh",
						}}
					>
						<div
							style={{
								fontSize: "1.2rem",
								marginBottom: "3vh",
								fontFamily: "Kanit",
								color: "#FFFFFF",
							}}
						>
							<a href="https://cricinshots.com/privacypolicy/" style={{ textDecoration: "none", color: "white" }}>
								PRIVACY POLICY
							</a>
						</div>
					</div>
				</Row>
				<Row>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							// marginTop: "6vh",
						}}
					>
						<div
							style={{
								fontSize: "1.2rem",
								marginBottom: "3vh",
								fontFamily: "Kanit",
								color: "#FFFFFF",
							}}
						>
							<a href="https://cricinshots.com/terms/" style={{ textDecoration: "none", color: "white" }}>
								TERMS
							</a>
						</div>
					</div>
				</Row>
			</Container>
		</section>
	);
};
