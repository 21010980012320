import { Button, Col, Container, Row } from "react-bootstrap";
import { gameplay1, gameplay3 } from "../../text";
import './gameplay.css';

export const GameplayPhone = () => {
	return (
		<section style={{ maxWidth: "100vw", minHeight: "100vh",marginTop:"-15px",marginBottom:"-15px", background: "#0B0B1E",
		//  overflowX: "hidden"
		  }} id="gameplay">
			<Container style={{ width: "100%", position: "relative", zIndex: 100 }}>

				<div className="box" style={{ margin: "10px" }}>
					<Row>
						<Col style={{ color: "#e3e3e3", marginTop: "40px", fontSize: "4rem", fontFamily: "Heading", textAlign: "center" }}>
							GAMEPLAY
						</Col>
					</Row>

					<Row style={{ fontSize: "14px", color: "#f0f0f0", fontFamily: "Kanit", textAlign: "center" }}>
						<Col>
							{gameplay3}
						</Col>
					</Row>

					<Row style={{width:"120%"}}>
						<Col style={{ display: "flex", justifyContent: "center", marginBottom: "100px", width: "100%", height:"104%" , margin:"-20px" }}>
							<img src={process.env.PUBLIC_URL + "/images/gameplay.png"} style={{width:"100%",height:"100%", objectFit: "cover"}} />
						</Col>
					</Row>

					{/* <Row>
						<Col style={{ display: "flex", justifyContent: "center", marginBottom: "80px" }}>
							<Button style={{ background: "rgb(255, 255, 255)", boxShadow: "rgb(0 0 0 / 99%) 4px 7px 19px -8px", borderRadius: "11px", width: "90%", padding: "15px", fontFamily: "Russo one", fontSize: "1.34rem", color: "#363636" }} onClick={() => { window.open("https://youtu.be/cX_68yRrpIs", "_blank"); }}>
								TEASER
							</Button>
						</Col>
					</Row> */}
				</div>

			</Container>
		</section>
	);
};
