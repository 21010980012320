export const gameplay1 =
	"Build your deck, battle your opponents, and win real life rewards.Test our awesome new PvP gameplay in the nets before we release to a wider audience. Pre-register now!";
export const gameplay2 =
	'"Game Mode" -> Nets = Test our awesome new PvP gameplay in the nets before we release to a wider audience. Pre-register now!';
export const gameplay3='The gameplay is built around a deck of player, tactics, and equipment cards. The strategic deployment of this deck can significantly impact the trajectory of the match, ensuring a suspense-filled and engaging experience. Transform from a fan to a strategist and lead your team through victories to become a legend in cricket land.'
export const phase1 = "Closed alpha of core gameplay";
export const phase2 = "Core gameplay and secondary loop beta launch on testnet";
export const phase3 = "Land NFT drop and stadium progression. Gameplay drop on mainnet";
export const phase4 = "Cricinshots Galaxy League. A worldwide cricket strategy tournament.";

export const newsDesc = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo molestiae ducimus mollitia quasi necessitatibus animi similique ratione est dolore doloremque maiores quaerat doloribus incidunt, perspiciatis obcaecati officia consequatur numquam? Temporibus!";
export const news1 = "Nulla vitae elit libero, a pharetra augue mollis interdum.";
export const news2 = "Nulla vitae elit libero, a pharetra augue mollis interdum.";
export const news3 = "Nulla vitae elit libero, a pharetra augue mollis interdum.";
export const newsheading1 = "News heading # 01";
export const newsheading2 = "News heading # 02";
export const newsheading3 = "News heading # 03";
