import Anchors from "./Anchors";
const Header = () => {
	return (
		<div className="header">
			<Anchors />
		</div>
	);
};

export default Header;
