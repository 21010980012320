import { Button, Col, Container, Row } from "react-bootstrap";
import "./gameplay2landScape.css";
import bcplImage1 from './bcpl1.png';
import bcplImage2 from './BCPL.png';
import verticalBackground from './verticalBackground.png';


export const Gameplay2Phone = () => {

	const redirectToPage = (url:string) => {
		window.location.href = url;
	  };

	return (
		
		<section className="bcpl-section-1" style={{ 
			height: "100vh",
			width: "100vw",
			position: "relative",
			backgroundSize: "cover",
			backgroundPosition:"bottom",
			backgroundImage: `url(${verticalBackground})` }}>
		<Container className="bcpl-container" style={{ height: '80vh', paddingTop: '10vh' }}>
		  <Row className="bcpl-row-1" style={{ height: '100%' }}>
			<Col md={6} className="text-center">
			  <img
				src={bcplImage1}
				style={{ objectFit: 'contain', width: '100%', height: '100%', filter: 'drop-shadow(5px 5px 10px #222)',borderTop:"210px" }}
				alt="BCPL"
			  />
			</Col>
			<Col md={6} style={{textAlign:"center"}}>
			  <img src={bcplImage2} className="bcpl-s1-text-image" alt="BCPL Text" style={{
					width: "80%",
					marginLeft: "-5%",
					fontFamily: 'Kanit',
					fontSize: "1.35vw",
					marginTop: "7vh",
					marginBottom: "3.5vh",
					}} />
			 
			  <div className="text-center">
				<Button
				  className="bcpl-button bcpl-s1-button" style={{ 
					backgroundColor: "black",
				  borderRadius: "2px",
				  padding: "10px 25px",
				  textAlign: "center",
				  color: "white",
				  border: "2px solid transparent",
				  alignContent:"center",
				  float:"none"
				}}
				  onClick={() => redirectToPage('https://cricinshots.com/bcpl/get/')}
				>
				  Download Now
				</Button>
			  </div>
			</Col>
		  </Row>
		</Container>
	  </section>
	);
};
