import PropTypes from "prop-types";

const Anchor = (props: { anchorText: string; anchorClass?: string; anchorLink?: string }) => {

	const style = props.anchorClass === "lastChild" ? {} : { alignContent:"center",right:"20%" };
	
	return (
		<>
			<a style= {style}

				href={props.anchorLink ? props.anchorLink : "#" + props.anchorText.toLowerCase()}
				className={props.anchorClass}
			>
				{props.anchorText}
			</a>
		</>
	);
};

Anchor.defaultProps = {
	anchorClass: "",
};

export default Anchor;
