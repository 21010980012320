import "./gameplay2landScape.css";

import { Button, Col, Container, Row } from "react-bootstrap";

import bcplImage1 from './bcpl1.png';
import bcplImage2 from './BCPL.png';
import background1 from './background1.png';

export const Gameplay2Landscape = () => {

	const redirectToPage = (url:string) => {
		window.location.href = url;
	  };

	return (
		<section className="bcpl-section-1" style={{ backgroundImage: `url(${background1})` }}>
      <Container className="bcpl-container" style={{ height: '80vh', paddingTop: '10vh' }}>
        <Row className="bcpl-row-1" style={{ height: '100%' }}>
          <Col md={6} className="text-center">
            <img
              src={bcplImage1}
              style={{ objectFit: 'contain', width: '100%', height: '100%', filter: 'drop-shadow(5px 5px 10px #222)' }}
              alt="BCPL"
            />
          </Col>
          <Col md={6}>
            <img src={bcplImage2} className="bcpl-s1-text-image" alt="BCPL Text" />
            <div className="bcpl-s1-text">
              Welcome to the "Bharat Cricket Premier League", the ultimate cricket game that takes you on an adventure across Bharat - a land of exciting cricket matches and cricket challenges. 🌍✈️
            </div>
            <div className="text-center">
              <Button
                className="bcpl-button bcpl-s1-button"
                onClick={() => redirectToPage('https://cricinshots.com/bcpl/get/')}
              >
                Download Now
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

	);
};
