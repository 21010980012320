import { Modal } from "react-bootstrap";

export const SuccessRegisterModal = ({ show, setShow }: { show: boolean; setShow: (show: boolean) => void }) => {
	return (
		<Modal
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}
		>
			<Modal.Body
				style={{
					background: "#1d1d34",
					color: "white",
					textAlign: "center",
					fontFamily: "Russo One",
					height: "25vh",
					display: "flex",
				}}
			>
				<h2 className="m-auto p-2 border border-2 rounded border-success">You have successfully Registered</h2>
			</Modal.Body>
		</Modal>
	);
};
